/////////////////////////
//  Path
/////////////////////////
$img-path               : '../img/';



/////////////////////////
//  Typography
/////////////////////////
$base-font-size         : 62.5%; // Cheat typo — Explenation 05_typography.sass
$main-line-height       : 1.5;
$main-font-family       : 'Sailec', Arial, Helvetica, sans-serif;



/////////////////////////
//   Grid sttings & Breakpoints
/////////////////////////
$site-width             : 1304px;
$breakpoints            : ( small: (0px), medium: (768px), large: (1024px), xlarge: (1304px) );

$grid-columns           : 12;
$column-gap             : 3.2rem;
$column-small-gap       : 2rem;


/////////////////////////
//   POP-UP
/////////////////////////
$popup-height           : 8rem;


/////////////////////////
//   Transiton
/////////////////////////
$timeShort              : 0.1s;
$time                   : 0.2s;
$timeLong               : 0.4s;
$easy-In                : cubic-bezier(.59,.35,.22,1);
$easy-Out               : cubic-bezier(.99,.01,.58,1);
$gEas                   : $easy-In;


/////////////////////////
//   Colors
/////////////////////////
$white   : #ffffff;
$gray    : #A9A8A6;
$dgray   : #807f7d;
$black   : #000000;

$politik : #bfd9fb;
$alltag  : #ccfcd1;
$kultur  : #f7cbae;
$dossier : #fffeab;
$blogs   : #eee2ee;
$intern  : #f1ded0;
$intern2 : #e6dcd7;

$error   : #dd4040;
$success : #66dd9a;

$color_to_var: ( politik: $politik, alltag: $alltag, kultur: $kultur, dossier: $dossier, blog: $blogs, intern: $intern, intern2: $intern2 );

@each $name, $color in $color_to_var {
    .#{$name}, .rubrik-#{$name} {
        --prim: #{$color}; }
    .bg-#{$name} {
        background-color: $color; }
    .color-#{$name} {
        color: $color; } }

/////////////////////////
//   Login Colors
/////////////////////////
$background-color        : $white;
$main-color              : #0000FF;
$form-bg                 : $white;


/////////////////////////
//  Forms
/////////////////////////
$input-radius           : 0;
$input-border           : 1px solid $black;
$input-padding          : .5em .7em;
$select-padding         : .3em .4em;
$input-background       : transparent;


/////////////////////////
//  Buttons
/////////////////////////
$button-radius           : 0px;
$button-border           : 1px solid $black;
$button-padding          : .3em .7em;
$button-background       : inherit;
$button-hover-background : inherit;
$button-font-size        : initial;
$button-line-height      : $main-line-height;
$button-transition       : background-color .15s $easy-In;
