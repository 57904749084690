// Typo


p, h1, h2, h3, h4, h5, h6, ul, ol, hr, blockquote {
    margin: 0;
    padding: 0; }

a {
    text-decoration: none;
    transition: opacity .2s ease;
    opacity: 1;
    color: inherit; }

p {
    margin-bottom: 1em;
    letter-spacing: .02em; }

ul, ol {
    margin: 0;
    padding: 0;
    list-style: none; }

cite, figcaption {
    display: block;
    font-size: 1.5rem;
    font-style: normal;
    margin-top: 1.8rem;
    line-height: 1.8rem; }


h1 {
    font-size: 3.6rem;
    line-height: 1.06em;
    margin-bottom: 2.7rem;
    font-weight: 400;
    @include breakpoint(medium) {
        font-size: 5rem; }
    @include breakpoint(large) {
        font-size: 5rem; }
    @include breakpoint(xlarge) {
        font-size: 7.4rem; } }
h2 {
    font-size: 3rem;
    line-height: 3.6rem; }
h3 {
    font-size: 2.4rem;
    line-height: 3rem; }
h4 {
    font-size: 2rem;
    line-height: 3rem; }
h5 {
    font-size: 2rem; }
h6 {
    font-size: 1.8rem; }

table {
    text-align: left; }

th, td {
    padding: .5em 2em .5em 0; }

th {
    padding-top: 0; }

tr {
    border-bottom: 1px solid $gray; }


label {
    display: block;
    margin-bottom: .5em;
    cursor: pointer;

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    select,
    textarea {
        display: block;
        width: 100%;
        margin-top: .5em;
        margin-bottom: 1em; } }


input, select {
    display: block;
    border: $input-border;
    padding: $input-padding;
    border-radius: $input-radius;
    background-color: $input-background;
    outline: 0;
    margin-bottom: 1em;
    &:focus, &:active {
        //outline: 1px solid $black

        &::placeholder {
                opacity: .6; } }

    &::placeholder {
        opacity: 1;
        color: $black; }

    //&:valid
    //    border-color: $success

    &:disabled {
        opacity: 0.5;
        pointer-events: none; }

    // Remove Spinner on number fields
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    &[type=number] {
        -moz-appearance: textfield; }

    &[type="checkbox"] {
        display: inline;
        margin-right: .5em; }


    &[type="radio"] {
        display: inline;
        margin-right: .3em; } }

[disabled="disabled"] {
    opacity: .7;
    pointer-events: none; }

select {
    padding: $select-padding; }

textarea {
    @extend input; }

button,
.button,
input[type="submit"],
input[type="button"] {
    border: $button-border;
    background-color: transparent;
    padding: $button-padding;
    font-size: $button-font-size;
    line-height: $button-line-height;
    border-radius: $button-radius;
    margin-bottom: .5em;
    transition: $button-transition; }

[role="button"] {
    cursor: pointer; }

.form-submit {
    background-color: $button-background;
    &:hover {
        background-color: $button-hover-background;
        font-style: italic; } }

hr {
    margin-bottom: 1em;
    border-bottom: 0;
    border-color: $gray; }


.content-style {
    h2 {
        font-weight: 400;
        margin: 6rem 0 2.7rem 0; }
    h3 {
        margin: 3.5rem 0 2rem 0; }
    h4 {
        margin: 2rem 0 1rem 0; }
    p, li {
        font-size: 1.6rem;
        line-height: 1.5em;
        @include breakpoint(medium) {
            font-size: 1.8rem; }
        @include breakpoint(xlarge) {
            font-size: 2rem; } }
    p {
        margin-bottom: 3.5rem; }
    ul {
        margin: 3.5rem 0 3.5rem 10rem;
        list-style: disc; }

    ol {
        margin: 3.5rem 0 3.5rem 10rem;
        list-style: number; }

    li {
        margin-bottom: 1rem; }

    a {
        border-bottom: 1px solid $black;
        font-weight: 500;
        white-space: normal;
        transition: background-color 0.1s;
        display: inline;
        line-height: 1em;
        .arrow {
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 1rem;
            @include breakpoint(medium) {
                width: 1.5rem;
                height: 1.5rem; } }
        &:hover {
            font-style: italic;
            box-shadow: inset 0 -0.6em var(--prim); } }

    blockquote {
        margin: 4rem 3rem;
        padding: 3rem 0 1rem 0;
        border: 2px solid black;
        border-color: var(--prim);
        border-left: 0px;
        border-right: 0px;
        @include breakpoint(medium) {
            margin: 6rem 8rem; }
        p {
            margin: 0 0 2rem 0;
            font-size: 2.4rem;
            line-height: 3.4rem;
            &::before {
                content: "«"; }
            &::after {
                content: "»"; } } } }

// Blockquote fix for importet Post
.importet-post .content-style blockquote p {
    &::before {
        content: ""; }
    &::after {
        content: ""; } }

// Font-weight Classes
body {
    .italic {
        font-style: italic; }

    .fw-regular {
        font-weight: 400; }
    .fw-medium {
        font-weight: 500; }
    .fw-bold {
        font-weight: 700; }

    // Font-Size Classes
    .fs-13-16 {
        font-size: 1.3rem;
        line-height: 1.6rem; }
    .fs-16-18 {
        font-size: 1.4rem;
        line-height: 1.8em;
        @include breakpoint(xlarge) {
            font-size: 1.6rem; } }
    .fs-18-22 {
        font-size: 1.5rem;
        line-height: 1.23em;
        @include breakpoint(medium) {
            font-size: 1.6rem; }
        @include breakpoint(xlarge) {
            font-size: 1.8rem; } }
    .fs-22-22 {
        font-size: 1.6rem;
        line-height: 1.6rem;
        @include breakpoint(medium) {
            font-size: 1.8rem;
            line-height: 1.8rem; }
        @include breakpoint(large) {
            font-size: 2.2rem;
            line-height: 2.2rem; } }
    .fs-3-36 {
        font-size: 2.2rem;
        line-height: 2.6rem;
        @include breakpoint(large) {
            font-size: 2.4rem;
            line-height: 1.3em; }
        @include breakpoint(xlarge) {
            font-size: 3rem;
            line-height: 3.6rem; } }
    .fs-34-44 {
        font-size: 2.8rem;
        line-height: 1.3em;
        @include breakpoint(large) {
            font-size: 2.5rem; }
        @include breakpoint(xlarge) {
            font-size: 3.4rem; } }
    .fs-44-48 {
        font-size: 2.8rem;
        line-height: 3.4rem;
        @include breakpoint(medium) {
            font-size: 4.4rem;
            line-height: 4.8rem; } }
    .fs-5-54 {
        font-size: 2.8rem;
        line-height: 1.3em;
        @include breakpoint(medium) {
            font-size: 5rem;
            line-height: 5.4rem; } } }

.info {
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.18em; }

