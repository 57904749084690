/////////////////////////
//  Breakpoints
/////////////////////////
@mixin breakpoint($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            @content; } }
    @else {
        @media only screen and (min-width: $breakpoint) {
            @content; } } }

@mixin breakpointDown($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media screen and (max-width: map-get($breakpoints, $breakpoint)-1) {
            @content; } }
    @else {
        @media only screen and (max-width: $breakpoint) {
            @content; } } }



/////////////////////////
//  Retina Background Image
/////////////////////////
@mixin retina($image) {
    $index: str-length($image) - 3;
    $image2x: str-insert($image, "@2x", $index);

    background-image: url($image);

    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13 / 10), only screen and (min-resolution: 120dpi) {
        background-image: url($image2x); } }



/////////////////////////
//  FontSearch
/////////////////////////
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace); }

    @return $string; }

@mixin font-face($name, $path, $weight: null, $style: null, $display: null, $exts: eot woff2 woff ttf svg) {
    $src: null;
    $extmods: (eot: "?", svg: "#" + str-replace($name, " ", "_"));
    $formats: (otf: "opentype", ttf: "truetype");

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma); }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        font-display: $display;
        src: $src; } }



/////////////////////////
//  My Mixins
/////////////////////////

// Background-cover
@mixin background-cover($url) {
  background-image: url($url);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
