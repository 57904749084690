@charset "UTF-8";
.politik, .rubrik-politik {
  --prim: #bfd9fb; }

.bg-politik {
  background-color: #bfd9fb; }

.color-politik {
  color: #bfd9fb; }

.alltag, .rubrik-alltag {
  --prim: #ccfcd1; }

.bg-alltag {
  background-color: #ccfcd1; }

.color-alltag {
  color: #ccfcd1; }

.kultur, .rubrik-kultur {
  --prim: #f7cbae; }

.bg-kultur {
  background-color: #f7cbae; }

.color-kultur {
  color: #f7cbae; }

.dossier, .rubrik-dossier {
  --prim: #fffeab; }

.bg-dossier {
  background-color: #fffeab; }

.color-dossier {
  color: #fffeab; }

.blog, .rubrik-blog {
  --prim: #eee2ee; }

.bg-blog {
  background-color: #eee2ee; }

.color-blog {
  color: #eee2ee; }

.intern, .rubrik-intern {
  --prim: #f1ded0; }

.bg-intern {
  background-color: #f1ded0; }

.color-intern {
  color: #f1ded0; }

.intern2, .rubrik-intern2 {
  --prim: #e6dcd7; }

.bg-intern2 {
  background-color: #e6dcd7; }

.color-intern2 {
  color: #e6dcd7; }

@font-face {
  font-family: "Sailec";
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/SailecRegularItalic/font.woff") format("woff"), url("../fonts/SailecRegularItalic/font.woff2") format("woff2"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/SailecRegular/font.woff") format("woff"), url("../fonts/SailecRegular/font.woff2") format("woff2"); }

@font-face {
  font-family: "Sailec";
  font-style: italic;
  font-weight: 500;
  font-display: auto;
  src: url("../fonts/SailecMediumItalic/font.woff") format("woff"), url("../fonts/SailecMediumItalic/font.woff2") format("woff2"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url("../fonts/SailecMedium/font.woff") format("woff"), url("../fonts/SailecMedium/font.woff2") format("woff2"); }

@font-face {
  font-family: "Sailec";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url("../fonts/SailecBold/font.woff") format("woff"), url("../fonts/SailecBold/font.woff2") format("woff2"); }

.preload-italic-400 {
  font-style: italic;
  font-weight: 400; }

.preload-italic-500 {
  font-style: italic;
  font-weight: 500; }

p, h1, h2, h3, h4, h5, h6, ul, ol, hr, blockquote {
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  transition: opacity .2s ease;
  opacity: 1;
  color: inherit; }

p {
  margin-bottom: 1em;
  letter-spacing: .02em; }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

cite, figcaption {
  display: block;
  font-size: 1.5rem;
  font-style: normal;
  margin-top: 1.8rem;
  line-height: 1.8rem; }

h1 {
  font-size: 3.6rem;
  line-height: 1.06em;
  margin-bottom: 2.7rem;
  font-weight: 400; }
  @media only screen and (min-width: 768px) {
    h1 {
      font-size: 5rem; } }
  @media only screen and (min-width: 1024px) {
    h1 {
      font-size: 5rem; } }
  @media only screen and (min-width: 1304px) {
    h1 {
      font-size: 7.4rem; } }

h2 {
  font-size: 3rem;
  line-height: 3.6rem; }

h3 {
  font-size: 2.4rem;
  line-height: 3rem; }

h4 {
  font-size: 2rem;
  line-height: 3rem; }

h5 {
  font-size: 2rem; }

h6 {
  font-size: 1.8rem; }

table {
  text-align: left; }

th, td {
  padding: .5em 2em .5em 0; }

th {
  padding-top: 0; }

tr {
  border-bottom: 1px solid #A9A8A6; }

label {
  display: block;
  margin-bottom: .5em;
  cursor: pointer; }
  label input[type="text"], label textarea[type="text"],
  label input[type="number"],
  label textarea[type="number"],
  label input[type="email"],
  label textarea[type="email"],
  label input[type="password"],
  label textarea[type="password"],
  label select,
  label textarea {
    display: block;
    width: 100%;
    margin-top: .5em;
    margin-bottom: 1em; }

input, textarea, select {
  display: block;
  border: 1px solid #000000;
  padding: 0.5em 0.7em;
  border-radius: 0;
  background-color: transparent;
  outline: 0;
  margin-bottom: 1em; }
  input:focus::placeholder, textarea:focus::placeholder, input:active::placeholder, textarea:active::placeholder, select:focus::placeholder, select:active::placeholder {
    opacity: .6; }
  input::placeholder, textarea::placeholder, select::placeholder {
    opacity: 1;
    color: #000000; }
  input:disabled, textarea:disabled, select:disabled {
    opacity: 0.5;
    pointer-events: none; }
  input::-webkit-outer-spin-button, textarea::-webkit-outer-spin-button, input::-webkit-inner-spin-button, textarea::-webkit-inner-spin-button, select::-webkit-outer-spin-button, select::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  input[type=number], textarea[type="number"], select[type=number] {
    -moz-appearance: textfield; }
  input[type="checkbox"], textarea[type="checkbox"], select[type="checkbox"] {
    display: inline;
    margin-right: .5em; }
  input[type="radio"], textarea[type="radio"], select[type="radio"] {
    display: inline;
    margin-right: .3em; }

[disabled="disabled"] {
  opacity: .7;
  pointer-events: none; }

select {
  padding: 0.3em 0.4em; }

button,
.button,
input[type="submit"],
textarea[type="submit"],
input[type="button"],
textarea[type="button"] {
  border: 1px solid #000000;
  background-color: transparent;
  padding: 0.3em 0.7em;
  font-size: initial;
  line-height: 1.5;
  border-radius: 0px;
  margin-bottom: .5em;
  transition: background-color 0.15s cubic-bezier(0.59, 0.35, 0.22, 1); }

[role="button"] {
  cursor: pointer; }

.form-submit {
  background-color: inherit; }
  .form-submit:hover {
    background-color: inherit;
    font-style: italic; }

hr {
  margin-bottom: 1em;
  border-bottom: 0;
  border-color: #A9A8A6; }

.content-style h2 {
  font-weight: 400;
  margin: 6rem 0 2.7rem 0; }

.content-style h3 {
  margin: 3.5rem 0 2rem 0; }

.content-style h4 {
  margin: 2rem 0 1rem 0; }

.content-style p, .content-style li {
  font-size: 1.6rem;
  line-height: 1.5em; }
  @media only screen and (min-width: 768px) {
    .content-style p, .content-style li {
      font-size: 1.8rem; } }
  @media only screen and (min-width: 1304px) {
    .content-style p, .content-style li {
      font-size: 2rem; } }

.content-style p {
  margin-bottom: 3.5rem; }

.content-style ul {
  margin: 3.5rem 0 3.5rem 10rem;
  list-style: disc; }

.content-style ol {
  margin: 3.5rem 0 3.5rem 10rem;
  list-style: number; }

.content-style li {
  margin-bottom: 1rem; }

.content-style a {
  border-bottom: 1px solid #000000;
  font-weight: 500;
  white-space: normal;
  transition: background-color 0.1s;
  display: inline;
  line-height: 1em; }
  .content-style a .arrow {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem; }
    @media only screen and (min-width: 768px) {
      .content-style a .arrow {
        width: 1.5rem;
        height: 1.5rem; } }
  .content-style a:hover {
    font-style: italic;
    box-shadow: inset 0 -0.6em var(--prim); }

.content-style blockquote {
  margin: 4rem 3rem;
  padding: 3rem 0 1rem 0;
  border: 2px solid black;
  border-color: var(--prim);
  border-left: 0px;
  border-right: 0px; }
  @media only screen and (min-width: 768px) {
    .content-style blockquote {
      margin: 6rem 8rem; } }
  .content-style blockquote p {
    margin: 0 0 2rem 0;
    font-size: 2.4rem;
    line-height: 3.4rem; }
    .content-style blockquote p::before {
      content: "«"; }
    .content-style blockquote p::after {
      content: "»"; }

.importet-post .content-style blockquote p::before {
  content: ""; }

.importet-post .content-style blockquote p::after {
  content: ""; }

body .italic {
  font-style: italic; }

body .fw-regular {
  font-weight: 400; }

body .fw-medium {
  font-weight: 500; }

body .fw-bold {
  font-weight: 700; }

body .fs-13-16 {
  font-size: 1.3rem;
  line-height: 1.6rem; }

body .fs-16-18 {
  font-size: 1.4rem;
  line-height: 1.8em; }
  @media only screen and (min-width: 1304px) {
    body .fs-16-18 {
      font-size: 1.6rem; } }

body .fs-18-22 {
  font-size: 1.5rem;
  line-height: 1.23em; }
  @media only screen and (min-width: 768px) {
    body .fs-18-22 {
      font-size: 1.6rem; } }
  @media only screen and (min-width: 1304px) {
    body .fs-18-22 {
      font-size: 1.8rem; } }

body .fs-22-22 {
  font-size: 1.6rem;
  line-height: 1.6rem; }
  @media only screen and (min-width: 768px) {
    body .fs-22-22 {
      font-size: 1.8rem;
      line-height: 1.8rem; } }
  @media only screen and (min-width: 1024px) {
    body .fs-22-22 {
      font-size: 2.2rem;
      line-height: 2.2rem; } }

body .fs-3-36 {
  font-size: 2.2rem;
  line-height: 2.6rem; }
  @media only screen and (min-width: 1024px) {
    body .fs-3-36 {
      font-size: 2.4rem;
      line-height: 1.3em; } }
  @media only screen and (min-width: 1304px) {
    body .fs-3-36 {
      font-size: 3rem;
      line-height: 3.6rem; } }

body .fs-34-44 {
  font-size: 2.8rem;
  line-height: 1.3em; }
  @media only screen and (min-width: 1024px) {
    body .fs-34-44 {
      font-size: 2.5rem; } }
  @media only screen and (min-width: 1304px) {
    body .fs-34-44 {
      font-size: 3.4rem; } }

body .fs-44-48 {
  font-size: 2.8rem;
  line-height: 3.4rem; }
  @media only screen and (min-width: 768px) {
    body .fs-44-48 {
      font-size: 4.4rem;
      line-height: 4.8rem; } }

body .fs-5-54 {
  font-size: 2.8rem;
  line-height: 1.3em; }
  @media only screen and (min-width: 768px) {
    body .fs-5-54 {
      font-size: 5rem;
      line-height: 5.4rem; } }

.info {
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.18em; }

.login {
  background-color: #ffffff;
  color: #0000FF; }
  .login a {
    color: #0000FF; }
  .login #login_error {
    border: 0px solid transparent;
    border: 3px solid #dc3232;
    color: #dc3232; }
  .login .message {
    border: 0px solid transparent;
    border: 3px solid green;
    color: green; }
  .login #login {
    padding: 0;
    width: 21rem;
    max-width: 90vw; }
    .login #login label {
      font-family: "Sailec", Arial, Helvetica, sans-serif;
      line-height: 1.5; }
  .login form {
    border: 3px solid #0000FF;
    padding-bottom: 25px; }
    .login form input, .login form textarea {
      border: 3px solid #0000FF !important;
      border-radius: 0 0 0 0;
      outline: none;
      -webkit-appearance: none;
      background-color: #ffffff;
      box-shadow: 0 0 0 0 transparent;
      transition: all 0.2s ease-in-out; }
      .login form input:focus, .login form textarea:focus, .login form input:active, .login form textarea:active, .login form input:visited, .login form textarea:visited {
        outline: none !important;
        box-shadow: 0 0 0 2px #0000FF !important; }
    .login form #rememberme {
      display: inline-block;
      width: 22px;
      height: 22px; }
    .login form .dashicons {
      color: #0000FF; }
    .login form .user-pass-wrap {
      margin-bottom: 5px; }
      @media only screen and (min-width: 768px) {
        .login form .user-pass-wrap {
          margin-bottom: 20px; } }
    .login form #wp-submit {
      color: #0000FF;
      background-color: transparent;
      font-size: 14px;
      border-radius: 0 0 0 0;
      width: 100%;
      margin-top: 20px; }
      .login form #wp-submit:focus, .login form #wp-submit:active, .login form #wp-submit:visited, .login form #wp-submit:hover {
        background-color: transparent;
        outline: none !important;
        border: 0px solid transparent;
        box-shadow: 0 0 0 2px #0000FF !important; }
      @media only screen and (min-width: 768px) {
        .login form #wp-submit {
          margin-top: 0px;
          width: auto; } }
    .login form .button-secondary.wp-hide-pw {
      padding: 0px;
      height: 38px;
      width: 38px;
      min-height: 38px;
      min-width: 38px;
      margin: 3px; }
      .login form .button-secondary.wp-hide-pw:focus, .login form .button-secondary.wp-hide-pw:active, .login form .button-secondary.wp-hide-pw:visited {
        border-radius: 0 0 0 0;
        border: 0px solid transparent;
        box-shadow: 0 0 0 1px #0000FF !important; }
      .login form .button-secondary.wp-hide-pw:hover {
        border: 0px solid transparent;
        box-shadow: 0 0 0 2px transparent !important; }
  .login #nav a, .login #backtoblog a {
    color: #0000FF; }
    .login #nav a:focus, .login #nav a:active, .login #nav a:visited, .login #nav a:hover, .login #backtoblog a:focus, .login #backtoblog a:active, .login #backtoblog a:visited, .login #backtoblog a:hover {
      color: #0000FF;
      box-shadow: none;
      font-weight: bold; }
  .login h1 a {
    background: url("../img/glunz/GLUNZ_logo.svg");
    width: 150px;
    height: 67px;
    margin: 0 auto 10vh auto; }
    @media only screen and (min-width: 768px) {
      .login h1 a {
        width: 200px;
        height: 90px;
        margin: 0 auto 20vh auto; } }
  .login #nav, .login #backtoblog {
    text-align: center;
    margin-top: 10px; }
    @media only screen and (min-width: 768px) {
      .login #nav, .login #backtoblog {
        margin-top: 20px; } }
  .login .privacy-policy-page-link {
    margin: auto;
    position: absolute;
    display: block;
    bottom: 5px;
    left: 0px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .login .privacy-policy-page-link {
        bottom: 20px; } }
    .login .privacy-policy-page-link .privacy-policy-link {
      display: block;
      position: relative; }
      .login .privacy-policy-page-link .privacy-policy-link:focus, .login .privacy-policy-page-link .privacy-policy-link:active, .login .privacy-policy-page-link .privacy-policy-link:visited, .login .privacy-policy-page-link .privacy-policy-link:hover {
        color: #0000FF;
        box-shadow: none;
        font-weight: bold; }
  .login #language-switcher {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center; }
    .login #language-switcher select, .login #language-switcher input, .login #language-switcher textarea {
      border: 1px solid #0000FF !important;
      border-radius: 0px;
      color: #0000FF; }

.interim-login.login h1 a {
  width: 150px;
  margin-bottom: 10vh; }
