/////////////////////////
//  Import Fonts
/////////////////////////
$font-path: '../fonts/';
$font-display: auto;
$font-families: ( 'Sailec' );
$font-variants: ( 'RegularItalic': ('weight': 400, 'style': italic, 'font-display': $font-display), 'Regular': ('weight': 400, 'style': normal, 'font-display': $font-display), 'MediumItalic': ('weight': 500, 'style': italic, 'font-display': $font-display), 'Medium': ('weight': 500, 'style': normal, 'font-display': $font-display), 'Bold': ('weight': 700, 'style': normal, 'font-display': $font-display)  );

@each $family in $font-families {
    @each $name, $attr in $font-variants {
        @include font-face($family, #{$font-path}#{$family}#{$name}/font, map-get($attr, 'weight'), map-get($attr, 'style'), map-get($attr, 'font-display'), woff woff2); } }

.preload-italic-400 {
    font-style: italic;
    font-weight: 400; }
.preload-italic-500 {
    font-style: italic;
    font-weight: 500; }
